/*
    Palette:
    #61892F
    #86C232
    #222629
    #474B4F
    #6B6E70
*/

.inline {
    display: inline-block;
}

a, a:visited, a:active, a:focus {
    color: #474B4F;
}

b {
    color: #61892F
}

.page {
    font-family: 'Roboto', sans-serif;
    text-align: justify;
    margin-top: 25px;
    animation: 0.1s linear 0s 1 opaticize;
    margin-bottom: 5%;
}

.page h1 {
    text-align: center;
    margin-bottom: 25px;
}

body {
    animation: 0.5s linear 0s 1 opaticize;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 1rem;
}

@keyframes opaticize {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

.btn.btn-primary:focus, .btn.btn-primary:hover {
    background: #61892F;
    border-color: #273713;
    color: #fff;
}

.btn.btn-primary {
    background: #474B4F;
    border-color: #2e3135;
    color: #fff;
}

.tile .tile-content:not(:last-child) {
    padding-right: 0.9rem;
}

.sub_image {
    vertical-align: sub;
}