.meh_logo {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 50px;
    transition: transform 7s;
    transition-timing-function: linear;
}

@media (max-width: 845px) {
    .meh_logo {
        width: 80px;
    }
}

@media (max-width: 500px) {
    .meh_logo {
        width: 50px;
    }
}

.meh_logo:hover {
    transform: rotate(-360deg);
}

@keyframes blink {
    0% {
        color: transparent;
    }
    50% {
        color: #61892F;
    }
    100% {
        color: transparent;
    }
}

mark.day {
    background: rgba(134, 194, 50, 0.18823529411764706);
    border-bottom: .05rem solid #86C232;
}

mark.night {
    background: #22262930;
    border-bottom: .05rem solid #222629;
}

.ciao_internet {
    font-family: 'Roboto Mono', monospace;
    font-weight: bold;
    color: #222629;
}

h3.info {
    padding-top: 30px;
    padding-bottom: 30px;
    color: #474B4F;
    text-align: left;
    font-weight: bold;
}

@media (max-width: 845px) {
    .ciao_internet {
        font-size: 33px;
    }
    h3.info {
        font-size: 1.4rem;
    }
}

@media (max-width: 500px) {
    .ciao_internet {
        font-size: 21px;
    }
    h3.info {
        font-size: 21px;
    }
}

.left-padded {
    padding-left: 5%;
}

@media (max-width: 845px) {
    .left-padded {
        padding-left: 0;
    }
}

dt.project-info {
    width: fit-content;
    background: rgba(134, 194, 50, 0.18823529411764706);
    border-bottom: .05rem solid #86C232;
}

dd.project-info {
    font-size: smaller;
    text-align: justify;
}