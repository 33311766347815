.tab-item a {
    border-bottom-color: #222629 !important;
    color: #61892F !important;
    font-family: 'Roboto Mono', monospace;
}

.tab-item:hover a, .tab-item.active {
    border-bottom-color: #61892F !important;
    color: #222629 !important;
    background: #61892f26;
}

.loading::after {
    border: .1rem solid #61892F;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
}

.tile {
    box-shadow: 0 4px 8px 0 #6B6E70;
    transition: .3s;
    padding: 1em;
    margin-top: 3%;
    border-radius: 3px;
    background: #fff;
    border: 1px solid #6B6E70;
    overflow: hidden;
    animation: 0.2s linear 0s 1 opaticize;
}

.tile h4 {
    font-family: 'Roboto Mono', monospace;
}

.tab.tab-block {
    justify-content: center;
    padding-bottom: 5px;
}

.tab.tab-block .tab-item {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
}

.github_link {
    width: 100%;
}

.github_button {
    float: right;
    width: 25%;
}

.github_stats {
    padding: 10px;
}

.github_stats_container {
    display: inline-block;
}

@media (max-width: 845px) {
    .github_button {
        width: 100%;
    }
    .github_stats_container {
        display: block;
        height: 48px;
    }
    h1.code-header {
        font-size: 33px;
    }
}

@media (max-width: 500px) {
    h1.code-header {
        font-size: 21px;
    }
}