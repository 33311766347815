.header {
    text-align: center;
    margin-top: 20px;
}

.ultrabold {
    font-weight: 800;
    margin-left: 5%;
    margin-right: 5%;
}

.animated_logo {
    cursor: pointer;
}

.animated_logo img {
    margin-left: 19px;
}

.animated_logo:hover img {
    transform: rotate(360deg);
    transition: 0.5s;
}

.menu-item {
    font-size: 33px;
    font-family: 'Roboto Mono', monospace;
    margin: 10px;
    color: #222629;
    line-height: 33px;
    transition: 0.1s;
    border-bottom: 3px solid transparent;
}

.animated_logo:hover .menu-item {
    border-bottom: 3px solid #86c232;
}

.menu-item.hover {
    border-bottom: 3px solid #86c23263;
}

@media (max-width: 845px) {
    .ultrabold {
        font-weight: normal;
    }
    h3 img {
        margin-left: 10px;
    }
    .animated_logo img {
        vertical-align: sub;
    }
    .ultrabold {
        margin-left: 3%;
        margin-right: 3%;
    }
    .menu-item {
        font-size: 20px;
        line-height: 20px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .animated_logo:hover .menu-item {
        border-bottom: 2px solid #86c232;
    }
    
    .menu-item.hover {
        border-bottom: 2px solid #86c23263;
    }
}

@media (max-width: 500px) {
    .menu-item {
        font-size: 12px;
        line-height: 12px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .animated_logo:hover .menu-item {
        border-bottom: 2px solid #86c232;
    }
    
    .menu-item.hover {
        border-bottom: 2px solid #86c23263;
    }
}